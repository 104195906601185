import { mapActions, mapMutations, mapState } from 'vuex'
import Echo from 'laravel-echo'

export const ws = {
  data () {
    return {
      ws_timer_notification: null
    }
  },
  computed: {
    ...mapState('notifications', ['count_unread', 'notifications']),
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('auth', ['getUser']),
    ...mapMutations('auth', ['setUserBalance']),
    ...mapMutations('actions', ['addAction']),
    ...mapActions('notifications', ['getUnread', 'getCountUnread', 'getNotifications']),
    joinWS () {
      this.getCountUnread()
      window.Echo = new Echo({
        broadcaster: 'socket.io',
        host: process.env.VUE_APP_SOCKET_HOST,
        auth: {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token') || ''}`
          }
        }
      })
      window.Echo.private('App.Models.User.' + this.user.id).notification((notification) => {
        if (notification.type === 'broadcast.notification') {
          clearTimeout(this.ws_timer_notification)
          this.ws_timer_notification = setTimeout(() => {
            this.getCountUnread()
          }, 2000)
        }
      }).listen('.users.updated-by-admin', (e) => {
        if (e.updated) {
          this.getUser()
        }
      })

      window.Echo.channel('user.' + this.user.id)
        .listen('.users.new-notification', (e) => {
          // this.getCountUnread()
        }).listen('.users.balance-changed', (e) => {
          this.setUserBalance(e.balance)
        }).listen('.actions.action_created', (e) => {
          this.addAction(e.action)
        })
    }
  }
}
